import { Injectable,Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlGroup, user } from '../models/config.model';
import { APIService } from './api.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(@Inject(ConfigService) private configService: ConfigService, private apiService: APIService) { }

  get urls(): UrlGroup {
    return this.configService.getApiUrls('adminconfiguration');
  }

  public GetUsersDetails(): Observable<user[]> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/clientconfiguration/allUsers`;
    return this.apiService.get<user[]>(url);
  }
  public GetUsersDetailsById(id:number): Observable<user> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/clientconfiguration/`+ id + `/GetUserById`;
    return this.apiService.get<user>(url);
  }
  public GetUsersDetailsByEmailId(userId: string): Observable<user[]> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/clientconfiguration/`+ userId +`/GetUserByEmailId`;
    return this.apiService.get<user[]>(url);
  }

  public postUsersDetails(data: any): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/clientconfiguration/CreateUser`;
    return this.apiService.post<number>(url, data);
  }

  public putUsersDetails(data: user): any {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/clientconfiguration/UpdateUser`;
    return this.apiService.put<number>(url, data);
  }
}
