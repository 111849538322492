<!-- <div class="container">
  <h1 style="font-weight:bold">403</h1>
  <h5>Sorry!!! you are not an authorized person...</h5>
  <span style="cursor:pointer;color:blue;text-decoration:underline" (click)='homepage()'>click here </span><span>to go home page..</span>
</div> -->
<div class="container">
  <h1>4<div class="lock"><div class="top"></div><div class="bottom"></div>
    <!-- </div>3</h1><p> 403 Access denied</p> -->
  </div>3</h1><p> Sorry!!! you are not an authorized person...</p>
    <span style="cursor:pointer;color:blue;text-decoration:underline" (click)='homepage()'>click here </span><span>to go home page..</span>
</div>