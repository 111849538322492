import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIService } from './api.service';
import { ConfigService } from './config.service';
import { UrlGroup, JobSetup } from '../models/config.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private configService: ConfigService,private apiService: APIService) { }

  get urls(): UrlGroup {
    return this.configService.getApiUrls('adminconfiguration');
  }

  public getUserInfoByUserEmail(emailId : string): Observable<any> {
    console.log(emailId);
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/clientconfiguration/`+emailId+`/getUserByUserId`;

    return this.apiService.get<any>(url);
  }

  public updateUserLastLogin(data : any): Observable<any> {

    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/clientconfiguration/updateLastLoginUser`;

    return this.apiService.put<any>(url,data);
  }

  public getAuthenticationToken(clientId,apiConsumerKey){
    let apiUrl = `adminconfiguration-read/v1/${clientId}/${apiConsumerKey}/authenticate`;
    console.log(apiUrl);
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead)+apiUrl;
    return this.apiService.get<any>(url);
  }
}
