<kendo-appbar *ngIf="NavbarService.visible">
  <kendo-appbar-section class="appbar-items">
    <div _ngcontent-c1="" class="nav-bar">
      <div _ngcontent-c1="" class="logo new-logo">
        <img _ngcontent-c1="" onclick="window.open('https://www.crawco.com')" alt="Crawford Claim Watcher"
          [src]="imgSrc" />
      </div>
    </div>
  </kendo-appbar-section>

  <kendo-appbar-section class="appbar-items">
    <div class="nav-menu">
      <ul>
        <li *ngFor="let m of MainHeaderMenuList">
          <a _ngcontent-doy-c0 class="overflow-button nav-item" [ngStyle]="{ color: '#fff' }" routerLink="{{m.link}}"
            routerLinkActive="active">{{m.name}}</a>
        </li>
        <!-- <li>
          <a><app-popup [popupMenu]="clientSetupMenu"></app-popup></a>
        </li> -->
        <!-- <li>
          <a _ngcontent-doy-c0 class="overflow-button nav-item" [ngStyle]="{ color: '#fff' }" routerLink="ClientSetup"
            routerLinkActive="active">Client Setup</a>
        </li>
        <li>
          <a _ngcontent-doy-c0 class="overflow-button nav-item" [ngStyle]="{ color: '#fff' }" routerLink="MasterData"
            routerLinkActive="active">Master Data</a>
        </li>
        <li>
          <a _ngcontent-doy-c0 class="overflow-button nav-item" [ngStyle]="{ color: '#fff' }"
            routerLink="OnDemandProcessing" routerLinkActive="active">On Demand Job Processing</a>
        </li>
        <li>
          <a _ngcontent-doy-c0 class="overflow-button nav-item" [ngStyle]="{ color: '#fff' }"
            routerLink="EmailConfiguration" routerLinkActive="active">Email Configuration</a>
        </li>
        <li>
          <a _ngcontent-doy-c0 class="overflow-button nav-item" [ngStyle]="{ color: '#fff' }" routerLink="History"
            routerLinkActive="active">History</a>

        </li>
        <li>
          <a _ngcontent-doy-c0 class="overflow-button nav-item" [ngStyle]="{ color: '#fff' }" routerLink="Users"
            routerLinkActive="active">Users</a>

        </li> -->
      </ul>
    </div>
  </kendo-appbar-section>

  <kendo-appbar-spacer></kendo-appbar-spacer>

  <kendo-appbar-section>
    <!-- <app-popup [style.width.px]="70"
      [popupMenu]="logOutMenu"
      [alignAnchor]="alignAnchor"
      [alignPopup]="alignPopup"
    ></app-popup> -->
    <!-- <div class="nav-menu mr-1" *ngIf="auth.isAuthenticated$ | async" > -->
    <!-- <div class="nav-menu mr-1">
      <ul>

        <li class="nav-item" (click)="logout()" style="cursor: pointer;">
          Logout
        </li>
      </ul>
    </div> -->
    <nav id="primary_nav_wrap" >
      <ul>
        <li style="cursor: pointer;">
          <span><i class="fa fa-user" aria-hidden="true" (click)="toggleUserPanel($event)"></i></span>
          <ul class="loginPanel" *ngIf="userLoginPanel">
            <li >{{userName}}</li>
            <li class = "logoutLi" (click)="logout()" >Logout</li>
          </ul>
        </li>
      </ul>
    </nav>
  </kendo-appbar-section>
</kendo-appbar>
