import { Component, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { DialogService, DialogCloseResult, DialogRef } from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';
import { OnInit, Inject } from '@angular/core';
import { GridDataResult, AddEvent, RemoveEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { map } from 'rxjs/operators';
import { MasterdataService } from 'src/app/services/masterdata.service';
import { ClientConfig } from 'src/app/interfaces/client-config';
import { Sourcesystem } from 'src/app/interfaces/sourcesystem';
import { Organizations } from 'src/app/models/config.model';
import { Trackedtables } from 'src/app/interfaces/trackedtables';
import { Triggertype } from 'src/app/interfaces/triggertype';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

@Component({
  selector: 'app-allorganizations',
  templateUrl: './allorganizations.component.html',
  styleUrls: ['./allorganizations.component.scss']
})
export class AllorganizationsComponent implements OnInit {

  public isTextboxVisible: boolean = false;
  public newData: any = '';
  public opened = false;
  public FileName: string = "";
  public newName: string = '';
  @ViewChild('editDialog') editDialog!: DialogRef;
  public orgName: string = "";
  public orgCode: string = "";
  public Title: string = "";
  public formData = { orgName: '' };
  public formValid = false;
  public isEditMode: boolean = false;
  @Input()
  id: number = 0;
  public organization: Organizations[] = [];
  public org :any = {

  };
  public pageSizes:boolean = true;
  public organizationForm: FormGroup;
  LoggedInName: string;
  get getFormControl() { return this.organizationForm.controls; }

  constructor(private dialogService: DialogService,@Inject(MasterdataService) private masterdataservice: MasterdataService, private fb: FormBuilder) {
    this.organizationForm = this.fb.group({
      id: new FormControl(),
      orgCode: new FormControl(this.org.orgCode,[Validators.required,Validators.pattern("^[a-zA-Z0-9]*$")]),
      orgName: new FormControl('',[Validators.required]),
      isActive: new FormControl(true),
      createdBy: new FormControl()
    })
    this.allData = this.allData.bind(this);

  }
  public allData():  ExcelExportData{
    const result: ExcelExportData = {
      data: process(this.organization, {

        sort: [{ field: "id", dir: "desc" }],
      }).data,


    };
    return result;
  }

    ngOnInit(): void {
      if(this.orgName == ''){
        this.isEditMode = true;
        this.getOrganizationData();
        console.log('getting organization');
      }
      console.log(this.isEditMode);
      console.log('data from read api:');
     this.masterdataservice.getAllOrganization().subscribe(data=>{ this.organization = data.reverse();
    console.log(data);});
  }

  public getFormData(): any {
    this.LoggedInName = sessionStorage.getItem('username');
    // if(this.LoggedInName == null || this.LoggedInName == '' || this.LoggedInName == undefined){
    //   this.LoggedInName = 'pranay';
    // }
    let data: Organizations = new Organizations();
    const formValue = this.organizationForm.getRawValue();
    if (this.isEditMode) {
      data.id = formValue.id;
      data.orgName = formValue.orgName;
      data.orgCode = formValue.orgCode;
      data.isActive = formValue.isActive;
      data.modifiedBy = this.LoggedInName;
    }
    else {
      data.orgName = formValue.orgName;
      data.orgCode = formValue.orgCode;
      data.createdBy = this.LoggedInName;
      data.isActive = true;

    }
    return (data);
  }

  public openDialog(orgName: string, orgCode: string, id: number, isActive: boolean) {
    this.isEditMode = true;
    this.orgName = orgName;
    this.orgCode = orgCode;
    this.id = id;
    this.organizationForm.patchValue({ orgName: orgName });
    this.organizationForm.patchValue({ orgCode: orgCode });
    this.organizationForm.patchValue({ id: id });
    this.organizationForm.patchValue({ isActive: isActive });

    this.Title = "Edit Organization";
    this.opened = true;
    console.log(this.orgName);
    console.log(this.orgCode);
    console.log(this.id);
    this.showTextbox();

  }

  public close(): void {

    this.opened = false;
  }
  public open(): void {
    this.opened = true;
    this.isEditMode = false;
    this.showTextbox();
    this.Title = "Add Organization";
    this.organizationForm = this.fb.group({
      orgCode: new FormControl(this.org.orgCode,[Validators.required,Validators.pattern("^[a-zA-Z0-9]*$")]),
      orgName: new FormControl('',[Validators.required]),
      isActive:[],
      id:[]

    })
  }
  public showTextbox() {
    this.isTextboxVisible = true;
  }

  public onInput(value: string) {
    this.newData = value;
  }
  public saveData() {
    let data = this.getFormData();
    console.log(data);
    if (this.orgName && this.isEditMode) {
      this.putOrganizationData(data);
    } else {
      this.postOrganizationData(data);
    }
    // this.getOrganizationData();
    // this.masterdataservice.getAllOrganization().subscribe(data=>{ this.organization = data;
    // });
  }

  public putOrganizationData(data: any) {
    this.masterdataservice.putAllOrganization(data).subscribe((resp) => {
      this.orgName = resp.orgName;
      this.orgCode = resp.orgCode;

      // this.id = resp.id;
      if (resp.isSuccess) {
        this.showConfirmation("Success","Organization updated successfully");
            console.log("Organization updated successfully");
        // this.masterdataservice.getAllOrganization();
      } else {
        this.showConfirmation("Error", "Error: " + resp.message);
        console.log("Error: " + resp.message);
      }
    }, (error: any) => {
      console.log("update error1");
    });
  }


  public postOrganizationData(data: any) {
    console.log(data);
    this.masterdataservice.postAllOrganization(data).
    subscribe((resp) => {
      // this.orgName = resp.orgName;
      // this.orgCode = resp.orgCode;
      //this.createdBy = resp.createdBy;
      if (resp.isSuccess) {
        this.showConfirmation("Success","Organization added successfully");
        console.log("Organization added successfully");
        // this.getOrganizationData();
      } else {
        this.showConfirmation("Error","Error: " + resp.message);
        console.log("Error: " + resp.message);
      }
    }, (error: any) => {
      console.log("error1");
    });
  }

  public getOrganizationData() {
      this.masterdataservice.getAllOrganization().subscribe(data => {
        this.organization = data.reverse();

        if (this.organization) {
          this.organizationForm.setValue(this.organization);
          console.log(this.organization);
        }
        console.log(this.organization)
      });

  }

  public result;
  public showConfirmation(dialogTitle, dialogContent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: dialogTitle,
      content: dialogContent,
      actions: [{ text: "Close", themeColor: "primary", cssClass: "modal-close-button" }],
      width: 400,
      height: 180,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
      } else {
        console.log("action", result);
        this.opened = false;
        this.getOrganizationData();
        // window.location.reload();

      }
      this.result = JSON.stringify(result);
    });
  }

}
