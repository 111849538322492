import { HttpClient } from '@angular/common/http';
import { Injectable,Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlGroup, JobSetup } from '../models/config.model';
import { JobName } from 'src/app/interfaces/jobName';
import { APIService } from './api.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class JobSetupService {
constructor(@Inject(ConfigService) private configService: ConfigService, private apiService: APIService) { }

  get urls(): UrlGroup {
    return this.configService.getApiUrls('adminconfiguration');
  }

  public GetClientJobSetUpByClientConfigurationId(clientConfigurationId : number): Observable<JobSetup> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/clientconfiguration/` + clientConfigurationId  + `/getClientJobSetupDetails`;
    return this.apiService.get<JobSetup>(url);
  }

  public getClientJobsDetails(clientId : number): Observable<JobName[]> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/clientconfiguration/` + clientId  + `/getClientJobsDetails`;
    return this.apiService.get<JobName[]>(url);
  }

  public postClientJobSetup(data: JobSetup): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/clientconfiguration/CreateClientJobSetup`;
    return this.apiService.post<number>(url, data);
  }

  public putClientJobSetup(data: JobSetup): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/clientconfiguration/updateClientJobSetup`;
    return this.apiService.put<number>(url, data);
  }
}
