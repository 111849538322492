import { Injectable } from '@angular/core';
import { APIService } from 'src/app/services/api.service';
import { UrlGroup } from 'src/app/models/config.model';
import { ConfigService } from 'src/app/services/config.service';
import { HttpClient } from '@angular/common/http';
import { JwtOptions } from '../jwt-options';

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {
  //accessToken: any;
  userId: string;
  get urls(): UrlGroup {
    return this.configService.getApiUrls('adminconfiguration');
  }
  constructor(private apiService:APIService,private configService:ConfigService,private http:HttpClient ) { }

  get accessToken(): string {
    return sessionStorage.getItem('token');
  }
  get expires_in(): string {
    return sessionStorage.getItem('expires_in');
  }
  public getAuthenticationToken(data){
    //let data = {}
    let apiUrl = `adminconfiguration-read/v1/clientconfiguration/authenticate`;
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead)+apiUrl;
    return this.http.post(url,data);
  }
  public getJwtInjectionConfig(): JwtOptions {
    return {
      token:  this.accessToken,
      expires_in:this.expires_in
      //userId: localStorage.getItem(this.userId) == null ? ' ' :
      //  localStorage.getItem(this.userId)
    };
  }
}
