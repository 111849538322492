import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Auth, Config, UrlGroup } from '../models/config.model';
import { of, Subject, BehaviorSubject, Observable } from 'rxjs';
import { AuthClientConfig } from '@auth0/auth0-angular';
@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  static settings: IAppConfig;
  private configVal: Config;

  private config: Subject<Config | undefined> = new BehaviorSubject<Config | undefined>(undefined)
  public config$ = this.config.asObservable();
  constructor(@Inject(HttpClient) private http: HttpClient, private authClientConfig: AuthClientConfig) {
  }
  public getConfig(): Config {
    return this.configVal;
  }

  public getApiUrls(type: string): UrlGroup {
    return this.configVal.apiUrls['adminconfiguration'];
  }

  public getAuthData(type: string): Auth {
    return this.configVal.auth;
  }
  public load() {
    return new Promise((resolve, reject) => {
      this.http.get<any>(`assets/config/config.json`).subscribe(config => {
        this.configVal = config;
        this.config.next(config);
        resolve(true);
      });
    });
  }

  // loadConfig() {
  //   //return new Promise<void>((resolve, reject) => {
  //     //this.httpClient = new HttpClient(this.handler);
  //     this.http.get<any>(`assets/config/config.json`).subscribe(response => {
  //       this.configVal = response;
  //       this.config.next(response);
  //       // ConfigService.settings = <IAppConfig>response;
  //       // this.authClientConfig.set({
  //       //   clientId: ConfigService.settings.AdminUIAuth0_clientID,
  //       //   domain: ConfigService.settings.OAuth0_Domain,
  //       //   authorizationParams: {
  //       //     redirect_uri: window.location.origin + "/callback"
  //       //   }
  //       // });

  //     //  resolve();
  //     })

  //  // });
  // }

  loadConfig() {
    // const url = this.http.get(this.urls.prefixurl) + `adminconfiguration-read/v1/clientconfiguration/` + clientConfigurationId + `/getClientConfigurationDetails`;
    const url = `assets/config/config.json`;
     this.http.get<any>(url).subscribe(response =>{
      this.configVal = response;
      this.config.next(response);
     });
  }

}

export interface IAppConfig {
  AdminUIAuth0_clientID: string
  OAuth0_Domain: string
}
