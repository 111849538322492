import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { SelectEvent } from "@progress/kendo-angular-layout";
import { process } from "@progress/kendo-data-query";
import { ClientService } from 'src/app/services/client.service';
import { ClientConfig } from 'src/app/interfaces/client-config';
import { MasterdataService } from '../services/masterdata.service';
import { Sourcesystem } from '../interfaces/sourcesystem';
import { Client } from '../interfaces/client';
import { Organizations } from '../interfaces/organizations';
import { UserAuthService } from '../auth/services/user-auth.service';

import {
  PageChangeEvent,
  GridDataResult,
  PagerPosition,
  PagerType,
} from "@progress/kendo-angular-grid";
import { Auth } from '../models/config.model';
import { ConfigService } from '../services/config.service';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
@Component({
  selector: 'app-clientsetup',
  templateUrl: './clientsetup.component.html',
  styleUrls: ['./clientsetup.component.scss']
})
export class ClientsetupComponent implements OnInit {

  public clientConfigurationId: number;
  public clientId: number;
  public showTabs: boolean = false;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective | undefined;
  public clientData: ClientConfig[] = [];
  public clientGridView: unknown[] = [];
  public mySelection: string[] = [];
  sourceSystemMasterData: Sourcesystem[] = [];
  clientMasterData: Client[] = [];
  orgMasterData: Organizations[] = [];
  //public position: PagerPosition = "bottom";
  //public type:PagerType  = "numeric";
  //public buttonCount = 5;
  //public info = true;
  //public previousNext = true;
  public pageSizes = true;
  constructor(@Inject(ClientService) private clientService: ClientService,
    @Inject(MasterdataService) private masterdataService: MasterdataService, private UserAuthService: UserAuthService, private configService: ConfigService) {
    this.allData = this.allData.bind(this);

  }
  get auth(): Auth {
    return this.configService.getAuthData('auth');
  }
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.clientGridView, {

        sort: [{ field: "clientConfigurationId", dir: "desc" }],
      }).data,


    };
    return result;
  }


  public ngOnInit(): void {
    localStorage.removeItem('clientId');
    localStorage.removeItem('clientName');
    localStorage.removeItem('sourceSystem');
    localStorage.removeItem('orgCode');
    localStorage.removeItem('clientConfigurationId');
    let clientId = this.auth.ClientID;
    let ClientSecret = this.auth.ClientSecret;
    let data = {'clientId':clientId,'ClientSecret':ClientSecret};
    this.UserAuthService.getAuthenticationToken(data).subscribe(res => {
      console.log(res);
      sessionStorage.setItem('token', res['access_token'])
      if (sessionStorage.getItem('token')) {
        this.clientService.getAllclientconfiguration().subscribe(data => {
          this.clientData = data.reverse();
          this.clientGridView = this.clientData;
        });
        this.masterdataService.getAllSourceSystem().subscribe(data => {
          this.sourceSystemMasterData = data.filter(s => s.isActive == true);
          //this.source = data;
        });
        this.masterdataService.getAllClient().subscribe(data => {
          this.clientMasterData = data.filter(s => s.isActive == true);
        });
        this.masterdataService.getAllOrganization().subscribe(data => {
          this.orgMasterData = data.filter(s => s.isActive == true);
        });
      }
    })
  }

  // public showGrid(): void {
  //   this.showTabs = false;
  // }

  public addClient(): void {
    this.showTabs = true;
    this.clientConfigurationId = 0;
    this.clientId = 0;
  }

  public editClient(value: ClientConfig): void {
    this.clientConfigurationId = value.clientConfigurationId;
    this.clientId = value.clientId;
    this.showTabs = true;
  }

  public onFilter(input: Event): void {
    const inputValue = (input.target as HTMLInputElement).value;

    this.clientGridView = process(this.clientData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "clientConfigurationId",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "clientName",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "organizationName",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "jobName",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "triggerType",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "jobStartTime",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "lastExecuted",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "createdBy",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "modifiedBy",
            operator: "contains",
            value: inputValue,
          }
        ],
      },
    }).data;

    // this.dataBinding?.skip = 0;
  }
}
