<div class="card-box">
  <div class="row">
    <kendo-grid
      [reorderable]="true"
      [kendoGridBinding]="triggerType"
      filterable="menu"
      [resizable]="true"
      cssClass="grid-height"
      [pageSize]="10"
      [sortable]="true"
      [groupable]="true"
      [pageable]="{
        pageSizes:pageSizes
      }"
      style="color: #fff"
    >
      <ng-template kendoGridToolbarTemplate>
        <kendo-grid-spacer></kendo-grid-spacer>
        <button
          kendoGridExcelCommand
          type="button"
          icon="file-excel"
          title="Download Excel"
        ></button>
        <button
          kendoGridPDFCommand
          type="button"
          icon="file-pdf"
          title="Download Pdf"
        ></button>
      </ng-template>

      <kendo-grid-column
        field="id"
        title="Id"
        [width]="60"
        filter="numeric"
        [headerClass]="'headerCustomClass'"
      ></kendo-grid-column>
      <kendo-grid-column
        field="type"
        title="Trigger Type"
        [width]="120"
        [headerClass]="'headerCustomClass'"
      ></kendo-grid-column>
      <kendo-grid-column
        field="isActive"
        filter="boolean"
        title="Is Active"
        [width]="100"
        [headerClass]="'headerCustomClass'"
      ></kendo-grid-column>

      <kendo-grid-pdf
        [fileName]="'Trigger type.pdf'"
        [allPages]="true"
        paperSize="A4"
        [repeatHeaders]="true"
        [landscape]="true"
        style="float: right"
      >
        <kendo-grid-pdf-margin
          top="2cm"
          left="1cm"
          right="1cm"
          bottom="2cm"
        ></kendo-grid-pdf-margin>
        <ng-template
          kendoGridPDFTemplate
          let-pageNum="pageNum"
          let-totalPages="totalPages"
        >
          <div class="page-template">
            <div class="header">
              <div style="float: right">
                Page {{ pageNum }} of {{ totalPages }}
              </div>
            </div>
            <div class="footer">Page {{ pageNum }} of {{ totalPages }}</div>
          </div>
        </ng-template>
      </kendo-grid-pdf>
      <kendo-grid-excel [fileName]="'Trigger type.xlsx'" [fetchData]="allData"></kendo-grid-excel>
    </kendo-grid>

    <br />
    <br />

  </div>
</div>
