import { Component, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { DialogService, DialogCloseResult, DialogRef } from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';
import { process } from "@progress/kendo-data-query";
import { OnInit, Inject } from '@angular/core';
import { MasterdataService } from 'src/app/services/masterdata.service';
import { Client } from 'src/app/models/config.model';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

@Component({
  selector: 'app-allclients',
  templateUrl: './allclients.component.html',
  styleUrls: ['./allclients.component.scss']
})

export class AllclientsComponent implements OnInit {

  public isTextboxVisible: boolean = false;
  public opened = false;
  public FileName: string = "";
  public newName: string = '';
  public name : string ="";
  public Title : string ="";
  public isActive: boolean=true;
  public loader: boolean=true;

  public isEditMode: boolean = false;
  public pageSizes:boolean = true;
  @Input()
  id: number = 0;
  public client: Client[] = [];
  public Data = [];

  public clientForm: FormGroup;
  LoggedInName: string;
  get getFormControl() { return this.clientForm.controls; }


  constructor(private dialogService: DialogService, @Inject(MasterdataService) private masterdataservice: MasterdataService, private fb: FormBuilder) {
    this.clientForm = this.fb.group({
      id: new FormControl(),
      isActive: new FormControl(true),
      name: new FormControl('',[Validators.required]),
      createdBy: new FormControl()
    })
    this.allData = this.allData.bind(this);

  }
  public allData():  ExcelExportData{
    const result: ExcelExportData = {
      data: process(this.client, {

        sort: [{ field: "id", dir: "desc" }],
      }).data,


    };
    return result;
  }


    ngOnInit(): void {
    this.getClientData();
      if(this.name == ''){
        this.isEditMode = true;
        this.getClientData();
      }
     this.masterdataservice.getAllClient().subscribe(data=>{ this.client = data.reverse();
    });
  }

  public getFormData(): any {
    this.LoggedInName = sessionStorage.getItem('username');
    // if(this.LoggedInName == null || this.LoggedInName == '' || this.LoggedInName == undefined){
    //   this.LoggedInName = 'pranay';
    // }
    let data: Client = new Client();
    const formValue = this.clientForm.getRawValue();
    console.log(this.clientForm.getRawValue());
    if (this.isEditMode) {
      data.name = formValue.name;
      data.id = formValue.id;
      data.isActive = formValue.isActive;
      data.modifiedBy = this.LoggedInName;
    }
    else {
      data.name = formValue.name;
      data.isActive = true;
      data.createdBy = this.LoggedInName;
    }
    return (data);
  }

  public openDialog(name: string, id: number, isActive: boolean) {
    this.isEditMode = true;
    this.name = name;
    this.id = id;
    this.clientForm.patchValue({ name: name });
    this.clientForm.patchValue({ id: id });
    this.clientForm.patchValue({ isActive: isActive });

    this.Title = "Edit Client";
    this.opened = true;
    console.log(this.name);
    this.showTextbox();
  }

  public close() {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
    this.isEditMode = false;
    this.showTextbox();
    this.Title = "Add Client";
    this.clientForm = this.fb.group({
      name:[],
      isActive:[],
      id:[]
    })


  }

  public showTextbox() {
    this.isTextboxVisible = true;
  }

  public saveData() {

    let data = this.getFormData();
    console.log(data);
    if (this.name && this.isEditMode) {
      this.putClientData(data);
      console.log("in1");
    } else {
      console.log("in2");


      this.postClientData(data);
    }

  }

  public putClientData(data: any) {
    this.masterdataservice.putAllClient(data).subscribe((resp) => {
      this.name = resp.name;
      if (resp.isSuccess) {
        this.showConfirmation("Success", "Client updated successfully");
        // this.masterdataservice.getAllClient();
      } else {
        this.showConfirmation("Error", "Error: " + resp.message);
      }
    }, (error: any) => {
      console.log("update error1");
    });
  }


  public postClientData(data: any) {
    console.log(data);
    this.masterdataservice.postAllClient(data).
      subscribe((resp) => {
        // this.name = resp.name;
        if (resp.isSuccess) {
          this.showConfirmation("Success", "Client added successfully");
          // this.getClientData();
        // this.masterdataservice.getAllClient();
        } else {
          this.showConfirmation("Error", "Error: " + resp.message);

        }
      }, (error: any) => {
        console.log("error1");
      });
  }

  public getClientData(){
      this.masterdataservice.getAllClient().subscribe(data=>{ this.client = data.reverse();
        if (this.client) {
          this.clientForm.setValue(this.client);
        }
      });

  }
  public result;
  public showConfirmation(dialogTitle, dialogContent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: dialogTitle,
      content: dialogContent,
      actions: [{ text: "Close", themeColor: "primary", cssClass: "modal-close-button" }],
      width: 400,
      height: 180,
      minWidth: 250,
    })
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {

      } else {
        console.log("action", result);
    // window.location.reload();
    this.getClientData();
    this.opened = false;
    console.log(this.getClientData());
      }
      this.result = JSON.stringify(result);
    });
    console.log(this.getClientData());


  }

}
