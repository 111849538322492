import { Component, Inject, Input, HostListener } from '@angular/core';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { MasterdataService } from 'src/app/services/masterdata.service';
import { TriggerEventService } from 'src/app/services/trigger-event.service';
import { Triggertype } from 'src/app/interfaces/triggertype';
import { Trackedtables, Timecode, Expensecode, TriggerEventConfigDetails } from 'src/app/models/config.model';
import { DialogService, DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { Console } from 'console';

@Component({
  selector: 'app-trigger-event',
  templateUrl: './trigger-event.component.html',
  styleUrls: ['./trigger-event.component.scss']
})
export class TriggerEventComponent {

  public triggerEventApiData: TriggerEventConfigDetails;
  public disableTriggerEventTab: boolean;

  @Input()
  clientConfigurationId: number;
  clientTriggerEventConfigurationId: number = 0;
  clientName: string;
  sourceSystem: string;
  orgCode: string;

  public isEditMode: boolean = false;
  public selectedTrackedTables: any[] = [];
  public selectedTimeCodes:any[] = [];
  public selectedExpenseCode:any[] = [];
  public triggerTypeData: Triggertype[] = [];
  public triggerType: Triggertype[] = [];
  public selectedExpenseCodeValue: Number = 0;
  public selectedTriggerTypeValue: Number = 0;
  public selectedTimeCodeValue: Number = 0;

  //public tableToTrackData: Trackedtables[] = [];
  // public tableToBeTrack: Trackedtables[] = [];

  //public timeCodeData: Timecode[] = [];
  public timeCode: Timecode[] = [];

  public expenseCodeData: Expensecode[] = [];
  public expenseCode: Expensecode[] = [];

  public trigtype: any = {};

  public triggerEventForm: FormGroup;

  public selectedSourceSystem: string | undefined;
  public selectedClientName: string | null;
  public selectedTriggerType: string | null;
  LoggedInName: string;
  @Input() allTrackedMasterData: any = [];
   @Input() expenseCodeMasterData: any = [];
  @Input() timeCodeMasterData: any = [];
 public selectedItems: Trackedtables[]=[];

  filteredAllTrackedData: any = [];
  filteredTimeCodeMasterData:any = [];
  // filteredExpenseCodeMasterData:any = [];
  get getFormControl() { return this.triggerEventForm.controls; }



  constructor(@Inject(TriggerEventService) private triggerEventService: TriggerEventService, @Inject(MasterdataService) private masterdataService: MasterdataService, private fb: FormBuilder, private dialogService: DialogService) {

    this.masterdataService.getAllTriggerType().subscribe(data => {
      this.triggerTypeData = data;
      this.triggerType = data;
    });
    this.triggerEventForm = this.fb.group({
      id: new FormControl(),
      triggerType: new FormControl(this.trigtype.triggerType, [Validators.required]),
      timeCodeToBeTracked: new FormControl(),
      //expenseCodeToBeTracked: new FormControl(),
      tableToTrack: new FormControl(this.trigtype.triggerType, [Validators.required]),
    })

  }

  @HostListener("document:click", ["$event"])
  public documentClick(event: KeyboardEvent): void {
    this.clientName = localStorage.getItem('clientName');
    this.sourceSystem = localStorage.getItem('sourceSystem');
    this.orgCode = localStorage.getItem('orgCode');
    if (!this.clientName || !this.sourceSystem || !this.orgCode) {
      this.disableTriggerEventTab = true;
      this.showConfirmation("Tab disabled", "Please configure Client first")
      console.log("this.showConfirmation");
    }
  }

  ngOnInit(): void {
    this.filteredAllTrackedData = this.allTrackedMasterData;
    this.filteredTimeCodeMasterData = this.timeCodeMasterData;
    //this.filteredExpenseCodeMasterData = this.expenseCodeMasterData;
    this.clientConfigurationId = Number(localStorage.getItem('clientConfigurationId'));
    this.selectedSourceSystem = localStorage.getItem('sourceSystem');
    //this.selectedItems = [this.allTrackedMasterData[0]];


    if (this.clientConfigurationId > 0) {
      this.getTriggerEventData(this.clientConfigurationId);
    }
    if (this.clientTriggerEventConfigurationId > 0) {
      this.isEditMode = true;
    }
  }

  public getFormData(): any {
    this.LoggedInName = sessionStorage.getItem('username');
    // if(this.LoggedInName == null || this.LoggedInName == '' || this.LoggedInName == undefined){
    //   this.LoggedInName = 'pranay';
    // }
    let data: TriggerEventConfigDetails = new TriggerEventConfigDetails();
    const formValue = this.triggerEventForm.getRawValue();
    console.log("formValue", formValue);
    localStorage.setItem('triggerType', formValue.triggerType.type);
    //if (formValue.clientTriggerEventConfigurationId > 0) {
    if (formValue.clientTriggerEventConfigurationId > 0) {
      data.id = formValue.clientTriggerEventConfigurationId;
    }
    data.clientId = Number(localStorage.getItem('clientId'));
    data.clientConfigurationId = this.clientConfigurationId;
    data.triggerTypeId = formValue.triggerType.id;

    data.trackedTablesId = formValue.tableToTrack ? formValue.tableToTrack.filter(s => s.isActive == true).map(itm => itm.id).join(',') : [];
    if(formValue.triggerType.id == 1){
    data.timeCodeId = formValue.timeCodeToBeTracked ?formValue.timeCodeToBeTracked.filter(s=>s.isActive).map(itm => itm.id).sort().join(',') :[];
    //data.expenseCodeId = formValue.expenseCodeToBeTracked ? formValue.expenseCodeToBeTracked.filter(s=>s.isActive).map(itm=>itm.id).sort().join(','):[];
    }
    console.log(data);
    data.isActive = Boolean(JSON.parse(localStorage.getItem('isActive')));
    if (+formValue.id > 0) {
      data.modifiedBy = this.LoggedInName;
    }
    else {
      data.createdBy = this.LoggedInName;
    }
    // }
    return data;
  }

  public saveTrigger(): void {
    let data = this.getFormData();
    console.log("data",data);
    if(this.triggerEventForm.getRawValue().tableToTrack?.find(t => t.tableName == 'Claim') == undefined){
      this.showConfirmation("Error","Please select Claim table from tracked tables");
    }

    else if (this.clientTriggerEventConfigurationId > 0) {
      this.updateClientTriggerEventConfiguration(data);
    } else {
      this.createClientTriggerEventConfiguration(data);
    }
  }

  public updateClientTriggerEventConfiguration(data: any) {
    this.triggerEventService.updateClientTriggerEventConfiguration(data).subscribe((resp) => {
      if (resp.isSuccess) {
        console.log("Trigger event updated successfully");
        this.showConfirmation("Success", "Trigger event updated successfully");
        this.clientConfigurationId = this.clientConfigurationId > 0 ? this.clientConfigurationId : Number(localStorage.getItem('clientConfigurationId'));
        this.getTriggerEventData(this.clientConfigurationId);
      } else {
        this.showConfirmation("Error", "Error: " + resp.message);
        console.log("Error: " + resp.message);
      }
    }, (error: any) => {
      console.log("update error1");
    });
  }

  public createClientTriggerEventConfiguration(data: any) {
    console.log("data", data);
    this.triggerEventService.createClientTriggerEventConfiguration(data).subscribe((resp) => {
      this.clientConfigurationId = this.clientConfigurationId > 0 ? this.clientConfigurationId : Number(localStorage.getItem('clientConfigurationId'));
      if (resp.isSuccess) {
        console.log("success");
        this.showConfirmation("Success", "Trigger event added successfully");
        this.getTriggerEventData(this.clientConfigurationId);
      } else {
        this.showConfirmation("Error", "Error: " + resp.message);
        console.log("Error: " + resp.message);
      }
    }, (error: any) => {
      console.log("error1");
    });
  }

  public clientNameChange(value: any): void {
    this.selectedClientName = value.name
  }

  public triggerTypeFilter(value: string) {
    this.triggerTypeData = this.triggerType;
    this.triggerTypeData = this.triggerType.filter(
      (s) => s.type.toLowerCase().indexOf(value?.toLowerCase()) !== -1
    );
  }

  public triggerTypeSelectionChange(value: any): void {
    this.selectedTriggerType = value.type;
    localStorage.setItem('triggerType', this.selectedTriggerType);
  }

  public tableToTrackFilter(value: string) {
    // this.tableToTrackData = this.tableToBeTrack;
    // this.tableToTrackData = this.tableToBeTrack?.filter(
    //   (s) => s.tableName.toLowerCase().indexOf(value?.toLowerCase()) !== -1
    // );
    //this.allTrackedMasterData = this.tableToBeTrack;
    this.filteredAllTrackedData = this.allTrackedMasterData?.filter(
      (s) => s.tableName.toLowerCase().indexOf(value?.toLowerCase()) !== -1
    );
  }

  public timeCodeFilter(value: string) {
    //this.timeCodeData = this.timeCode;
    this.filteredTimeCodeMasterData = this.timeCodeMasterData.filter(
      (s) => s.code.toLowerCase().indexOf(value?.toLowerCase()) !== -1
    );
  }

  // public expenseCodeFilter(value: string) {
  //   //this.expenseCodeData = this.expenseCode;
  //   this.filteredExpenseCodeMasterData = this.expenseCodeMasterData.filter(
  //     (s) => s.code.toLowerCase().indexOf(value?.toLowerCase()) !== -1
  //   );
  // }

  public getTriggerEventData(clientConfigurationId: number) {
    //this.masterdataService.getAllTrackedTable().subscribe(AllTrackedMasterData => {
    //this.tableToBeTrack = AllTrackedMasterData;
    let triggerTypeObj = {};
    let tableTrackData = [];
    let timeCodeData = [];
    //let expenseCodeData = [];
    this.triggerEventService.getClientTriggerEventConfigurationDetails(clientConfigurationId).subscribe(triggerEventApiData => {
      console.log("triggerEventApiData",triggerEventApiData);
      if (triggerEventApiData) {
        tableTrackData = (triggerEventApiData.trackedTablesId != null) ? this.allTrackedMasterData?.filter(item => triggerEventApiData.trackedTablesId?.split(',').includes(item.id.toString())) : '';
        timeCodeData = (triggerEventApiData.timeCodeId != null) ? this.timeCodeMasterData?.filter(item => String(triggerEventApiData.timeCodeId)?.split(',').includes(item.id.toString())) : '';
       // expenseCodeData = (triggerEventApiData.expenseCodeId != null) ? this.expenseCodeMasterData?.filter(item => String(triggerEventApiData.expenseCodeId)?.split(',').includes(item.id.toString())) : '';
        if(tableTrackData.length < 1){
          tableTrackData = [this.allTrackedMasterData[0]];
        }


        if (triggerEventApiData.triggerTypeId != 0) {
          this.selectedTriggerType = triggerEventApiData.triggerType;
          triggerTypeObj =  {id:triggerEventApiData.triggerTypeId,type:triggerEventApiData.triggerType}
          //console.log("triggerTypeObj",triggerTypeObj);
        }else{
          triggerTypeObj = null;
        }

        this.selectedClientName = localStorage.getItem('clientName')?.toString();
        this.clientTriggerEventConfigurationId = triggerEventApiData.clientTriggerEventConfigurationId;
        if (triggerEventApiData.trackedTablesId) {
          this.selectedTrackedTables = this.allTrackedMasterData?.filter(item => triggerEventApiData.trackedTablesId?.split(',').includes(item.id.toString()));
        }
        if(triggerEventApiData.timeCodeId != 0){
          this.selectedTimeCodes = this.timeCodeMasterData?.filter(item => String(triggerEventApiData.timeCodeId)?.split(',').includes(item.id.toString()));
        }
        // if(triggerEventApiData.expenseCodeId != 0){
        //   this.selectedExpenseCode = this.expenseCodeMasterData?.filter(item => String(triggerEventApiData.expenseCodeId)?.split(',').includes(item.id.toString()));
        // }

        this.triggerEventForm.patchValue({
          id: triggerEventApiData.clientTriggerEventConfigurationId,
          clientId: Number(localStorage.getItem('clientId')),
          clientName: localStorage.getItem('clientName')?.toString(),
          triggerType:triggerTypeObj,
          sourceSystem: localStorage.getItem('sourceSystem')?.toString(),
          //timeCodeToBeTracked: this.timeCodeMasterData.find(item => item.id === triggerEventApiData.timeCodeId),
          //expenseCodeToBeTracked: this.expenseCodeMasterData.find(item => item.id === triggerEventApiData.expenseCodeId),
          timeCodeToBeTracked:timeCodeData,
          //expenseCodeToBeTracked:expenseCodeData,
          tableToTrack: tableTrackData
        },);
      }
    });
  }

  public exit() {
    if(this.triggerEventForm.dirty){
      this.showConfirmationOnBack("You have unsaved data on the page.Do you want to continue?");
    }else{
      window.location.reload();
    }
  }

  public result;
  public showConfirmation(dialogTitle, dialogContent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: dialogTitle,
      content: dialogContent,
      actions: [{ text: "Close", themeColor: "primary", cssClass: "modal-close-button" }],
      width: 400,
      height: 150,
      minWidth: 250,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        console.log("close");
      } else {
        console.log("action", result);
      }
      this.result = JSON.stringify(result);
    });
  }

  public showConfirmationOnBack(dialogContent): void {
    const dialog: DialogRef = this.dialogService.open({
      title: "Please confirm",
      content: dialogContent,
      actions: [{ text: "Yes"}, { text: "No", themeColor: "primary" }],
      width: 350,
      height: 170,
    });

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        console.log("close");
      } else {
       if(result.text == "Yes"){
        window.location.reload();
       }
      }
      this.result = JSON.stringify(result);
    });
  }

  disabled() {
    if (this.clientTriggerEventConfigurationId > 0) {
      this.isEditMode = true;
    }
    if (this.isEditMode) {
      console.log(this.triggerEventForm.dirty);
      if (!this.triggerEventForm.valid || !this.triggerEventForm.dirty) {
        return true;
      }
    } else {
      if (!this.triggerEventForm.valid) {
        return true;
      }
    }
    return false;
  }

}
