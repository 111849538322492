import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Observable, of, Subject, BehaviorSubject, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class APIService {

  constructor(private http: HttpClient) { }

  
  post<T>(url: string, body: any, option?: any): Observable<T> {
    return this.http
      .post(url, body, { ...option, headers: {} })
      .pipe(catchError(this.handleErrorPassthrough(`POST: ${url}`)));
  };

  put<T>(url: string, body: any, option?: any): Observable<T> {
    return this.http
      .put(url, body, { ...option, headers: {} })
      .pipe(catchError(this.handleErrorPassthrough(`PUT: ${url}`)));
  };

  delete<T>(url: string): Observable<T> {
    return this.http.delete<T>(url).pipe(
      tap(_ => this.log(`fetched ${url}`)),
      catchError(this.handleError<T>(`DELETE: ${url}`, undefined))
    );
  }

  public get<T>(url: string): Observable<T> {
    return this.http.get<T>(url).pipe(
      tap(_ => this.log(`fetched ${url}`)),
      catchError(this.handleError<T>(`GET: ${url}`, undefined))
    );
  };

  private handleErrorPassthrough(operation = 'operation') {
    return (error: any): Observable<any> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return throwError(error);
    };
  };

  public sanitizeUrl(url: string): string {
    return url.endsWith('/') ? url : url + '/';
  }
 
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); 

      this.log(`${operation} failed: ${error.message}`);

      return of(result as T);
    };
  }

  private log(message: string) {
  }
}
