import { Injectable,Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { TwilioSMSClaimDetails } from '../models/config.model';
import { UrlGroup } from '../models/config.model';
import { APIService } from './api.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class TwilioSmsClaimdetailsService {

  constructor(@Inject(ConfigService) private configService: ConfigService, private apiService: APIService) { }

  get urls(): UrlGroup {
    return this.configService.getApiUrls('adminconfiguration');
  }

  public getTwilioSMSClaimDetails(): Observable<TwilioSMSClaimDetails[]> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/clientconfiguration/gettwilioSmsClaimDetails`;
    return this.apiService.get<TwilioSMSClaimDetails[]>(url);
  }
}
