import { Component, ViewEncapsulation, ViewChild, Input } from '@angular/core';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { DialogService, DialogCloseResult,DialogRef } from '@progress/kendo-angular-dialog';
import { Observable } from 'rxjs';
import {  OnInit, Inject } from '@angular/core';
import { GridDataResult, AddEvent, RemoveEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { map } from 'rxjs/operators';
import { MasterdataService } from 'src/app/services/masterdata.service';
import { ClientConfig } from 'src/app/interfaces/client-config';
import { Sourcesystem } from 'src/app/interfaces/sourcesystem';
import { Organizations } from 'src/app/interfaces/organizations';
import { Trackedtables } from 'src/app/interfaces/trackedtables';
import { Triggertype } from 'src/app/interfaces/triggertype';
import { Timecode } from 'src/app/interfaces/timecode';
import { Jobfrequency } from 'src/app/interfaces/jobfrequency';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

@Component({
  selector: 'app-alljobfrequency',
  templateUrl: './alljobfrequency.component.html',
  styleUrls: ['./alljobfrequency.component.scss']
})
export class AlljobfrequencyComponent implements OnInit {

  public isTextboxVisible: boolean = false;
  public newData: any = '';
  public opened = false;
  public FileName: string = "";
  public newName: string = '';
  @ViewChild('editDialog') editDialog!: DialogRef;
  public Name : string ="";
  public Title : string ="";
  public formData = { Name: ''};
  public formValid = false;
  public SourcesystemApiData: Sourcesystem;
  public isEditMode: boolean = false;
  public pageSizes:boolean = true;
  @Input()
  Id: number = 0;
  public jobFrequency: Jobfrequency[]=[];
  public Data=[];

  constructor(private dialogService: DialogService,@Inject(MasterdataService) private masterdataservice: MasterdataService) { }


    ngOnInit(): void {
      if(this.Id > 0){
        this.isEditMode = true;
      }
      console.log(this.isEditMode);
      console.log('data from read api:');
     this.masterdataservice.getAllJobfrequency().subscribe(data=>{ this.jobFrequency = data;
    console.log(data);});
    this.allData = this.allData.bind(this);

  }
  public allData():  ExcelExportData{
    const result: ExcelExportData = {
      data: process(this.jobFrequency, {

        sort: [{ field: "id", dir: "desc" }],
      }).data,


    };
    return result;
  }

  public openDialog(Name : string){
    this.Name = Name;
    this.Title = "Edit Entity";
    this.opened = true;
    console.log(this.Name);
    this.showTextbox();
  }

  public onCancel(){
    this.editDialog.close();
  }

  public onSave(){
    this.editDialog.close();
  }
  public close(status: string): void {
    console.log(`Dialog result: ${status}`);
    this.opened = false;
  }
  public open(): void {
    this.opened = true;
    this.showTextbox();
    this.Title = "Add Entity";
    this.Name="";

  }
  public showTextbox() {
    this.isTextboxVisible = true;
  }

  public onInput(value: string) {
    this.newData = value;
  }
  public saveData() {
    console.log(this.Name);

    this.newData = '';
    this.isTextboxVisible = false;
    this.opened = false;
  }
}
