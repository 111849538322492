import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { NavigationStart, Router } from '@angular/router';
import { ConfigService } from './services/config.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Client';
  userName: string;
  showFooter:boolean = false;
  constructor(private auth: AuthService, private router: Router,private ConfigService:ConfigService){
    this.ConfigService.loadConfig();
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'] == '/' || event['url'] == '/login') {
          this.showFooter = false;
        } else {
          // console.log("NU")
          this.showFooter = true;
        }
      }
    });

    //constructor(){
    /*
    @author : Pranay Palaskar
    @purpose : when user is authenticated then we are storing name in sessionstorage for multipurpose
    */
    this.auth.isAuthenticated$.subscribe(isAuthenticated=>{
        if(isAuthenticated){
          this.auth.user$.subscribe(userData =>{
            this.userName = userData.name;
            sessionStorage.setItem('username',userData.name);
          })
        }
    })
   }

}


