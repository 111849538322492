import { HttpClient } from '@angular/common/http';
import { Injectable,Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientConfig } from '../interfaces/client-config'
import { UrlGroup } from '../models/config.model';
import { APIService } from './api.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})

export class ClientService {
constructor(@Inject(ConfigService) private configService: ConfigService, private apiService: APIService) { }

get urls(): UrlGroup {
  return this.configService.getApiUrls('adminconfiguration');
}

public getClientConfiguration(clientConfigurationId: number): Observable<ClientConfig> {
  const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/clientconfiguration/` + clientConfigurationId + `/getClientConfigurationDetails`;
  return this.apiService.get<ClientConfig>(url);
}

public getAllclientconfiguration(): Observable<ClientConfig[]> {
  const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/clientconfiguration/allclientconfiguration`;
  return this.apiService.get<ClientConfig[]>(url);
}

public postClient(data: any): Observable<any> {
  const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/clientconfiguration/CreateClientConfiguration`;
  return this.apiService.post<number>(url, data);
}
public putClient(data: ClientConfig):  Observable<any> {
  const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/clientconfiguration/updateClientConfiguration`;
  return this.apiService.put<number>(url, data);
}

}
