import { AfterViewInit, Component, ElementRef, Input, NgZone, ViewChild, Inject } from '@angular/core';
import { AppBarThemeColor } from '@progress/kendo-angular-navigation';
import { Align } from '@progress/kendo-angular-popup';
import { Headermenu } from 'src/app/headermenu';

import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    "(window:click)": "closeUserPanel()"
  }
})
export class HeaderComponent implements AfterViewInit {
  @ViewChild("anchor", { static: false })
  public anchor: ElementRef<HTMLElement> | undefined;
  alignPopup: Align = { horizontal: "right", vertical: "top" };
  alignAnchor: Align = { horizontal: "right", vertical: "bottom" };
  public themColor: AppBarThemeColor = "light";
  public imgSrc = "./assets/logo-white.svg";

  public margin = { horizontal: -46, vertical: 7 };
  public show = false;
  showLogout: boolean = false;
  showFooter:boolean = false;
  MainHeaderMenuList = [{ name: "Client Setup", link: "/ClientSetup" },
  { name: "Master Data", link: "/MasterData" },
  { name: "On Demand Job Processing", link: "/OnDemandProcessing" },
  { name: "Email Configuration", link: "/EmailConfiguration" },
  { name: "History", link: "/History" },
  { name: "Users", link: "/Users" },
  ];
  clientSetupMenu: Headermenu = {
    name: "Client Setup",
    items: [{ text: "Client Configuration", link: "/ClientConfiguration" },
    { text: "Trigger Event", link: "/TriggerEvent" },
    { text: "Job Setup", link: "/JobSetup" },
    ]
  }
  masterDataMenu: Headermenu = {
    name: "MasterData",
    items: [{ text: "Source System", link: "/SourceSystem" },
    { text: "Trigger Type", link: "/TriggerType" },
    { text: "Tables to track", link: "/TablesToTrack" },
    { text: "Frequency", link: "/Frequency" },
    { text: "Clients", link: "/Clients" }
    ]
  }
  userLoginPanel: boolean = false;
  //userName:string = localStorage.getItem('username');
  @Input() userName;
  // logOutMenu: Headermenu =  {name:"Log Out",
  //                            items:  [{text: "My Profile", link:"/MyProfile"},
  //                                     {text: "Log Out", link:"/LogOut"}
  //                                     ]}

  public onToggle(): void {
    this.show = !this.show;
  }



  // constructor(private zone: NgZone) {}
  constructor(private zone: NgZone,
    @Inject(DOCUMENT) public document: Document,
    public auth: AuthService,
    public NavbarService: NavbarService,

  ) {


  }
  ngOnInit() {

    this.auth.isAuthenticated$.subscribe(isAuthenticated => {
      if (isAuthenticated) {
        this.auth.user$.subscribe(userData => {
          this.userName = userData.name;
          sessionStorage.setItem('username', userData.name);
        })
      }
    })



  }

  closeUserPanel(){
    this.userLoginPanel = false;
  }

//}

  public ngAfterViewInit(): void {
  this.zone.runOutsideAngular(() => {
    window.addEventListener("resize", () => {
      if (this.show) {
        this.zone.run(() => this.onToggle());
      }
    });
  });
}

logout() {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('username');
  sessionStorage.removeItem('role');
  sessionStorage.removeItem('expires_in');
  this.auth.logout({
    logoutParams: {
      returnTo: this.document.location.origin
    }
  });
}
toggleUserPanel($event: Event) {
  $event.stopPropagation();
  this.userLoginPanel = !this.userLoginPanel;

}




}
