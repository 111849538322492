<div class="card-box" *ngIf="disableTriggerEventTab">
  Tab disabled,Please configure Client first
</div>
<div class="card-box" *ngIf="!disableTriggerEventTab">
  <form class="k-form" [formGroup]="triggerEventForm">
    <div class="row">
      <div class="col-md-3 col-sm-12 mb-4">
        <kendo-formfield>
          <kendo-floatinglabel text="Select Trigger Type">
            <kendo-dropdownlist placeholder="" [filterable]="true" textField="type" valueField="id"
              (filterChange)="triggerTypeFilter($event)" (selectionChange)="triggerTypeSelectionChange($event)"
              [formControlName]="'triggerType'" [data]="triggerTypeData" #triggerType required>
            </kendo-dropdownlist>
          </kendo-floatinglabel>
          <kendo-formerror>Trigger Type required</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col-md-3 col-sm-12 mb-4" *ngIf="selectedSourceSystem === 'CMS'">
        <kendo-formfield>
          <kendo-label text="Select Table(s) To Be Tracked"></kendo-label>
          <kendo-multiselect placeholder="" [filterable]="true" [data]="filteredAllTrackedData"
            [value]="selectedTrackedTables" [checkboxes]="true" [autoClose]="false" [textField]="'tableName'"
            [valueField]="'id'" (filterChange)="tableToTrackFilter($event)"
            [formControlName]="'tableToTrack'"></kendo-multiselect>
          <kendo-formerror *ngIf="getFormControl['tableToTrack']?.errors?.['required']">Tables to be tracked is
            required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-sm-12 mb-4" *ngIf="selectedSourceSystem === 'CMS' && selectedTriggerType == 'Docket'">
        <kendo-label text="Select Time Code To Be Tracked"></kendo-label>
        <kendo-multiselect placeholder="" [checkboxes]="true"  [autoClose]="false"  [value]="selectedTimeCodes"  [filterable]="true" textField="code" valueField="id"
          (filterChange)="timeCodeFilter($event)" [formControlName]="'timeCodeToBeTracked'" [data]="filteredTimeCodeMasterData">
        </kendo-multiselect>
      </div>

      <!-- <div class="col-md-3 col-sm-12 mb-4" *ngIf="selectedSourceSystem === 'CMS' && selectedTriggerType == 'Docket'">
        <kendo-label text="Expense Code To Be Tracked"></kendo-label>
         <kendo-floatinglabel text="Expense Code To Be Tracked">
          <kendo-multiselect placeholder="" [value]="selectedExpenseCode" [checkboxes]="true"  [autoClose]="false"  [filterable]="true" textField="code" valueField="id"
            (filterChange)="expenseCodeFilter($event)" [formControlName]="'expenseCodeToBeTracked'"
            [data]="filteredExpenseCodeMasterData">
          </kendo-multiselect>
         </kendo-floatinglabel> 
      </div> -->
      <div class="col-md-3 col-sm-12 mb-4"></div>
    </div>

    <div class="row">
      <div class="col-md-3 col-sm-12 mb-4"></div>
      <div class="col-md-3 col-sm-12 mb-4"></div>
      <div class="col-md-3 col-sm-12 mb-4"></div>
    </div>

    <div class="row">
      <div class="col-md-3 col-sm-12 ">
        <button kendoButton _ngcontent-whw-c5 style="color: #fff; background-color: #193971 !important"
          [routerLink]="['../ClientSetup']" (click)="exit()">
          Back
        </button>
        <kendo-button _ngcontent-whw-c5 style="
            color: #fff;
            background-color: #193971 !important;
            margin-left: 30px;
          " (click)="saveTrigger()" [disabled]="disabled()">Save</kendo-button>
      </div>
    </div>
  </form>
  <div kendoDialogContainer></div>
</div>
