<div _ngcontent-c1="" class="login-container" >
    <div _ngcontent-c1="" class="left">
  
    </div>
    <div _ngcontent-c1="" class="right card">
      <div _ngcontent-c1="" class="logo new-logo">
        <img _ngcontent-c1="" src="../../assets/images/logo-blue.png">
        <!-- <img _ngcontent-c1="" src="../../assets/images/globalTechServ.png"> -->
        <!-- <div _ngcontent-c1="" style="padding: 16px; font-size: 16px; color: rgba(0,0,0,0.5)">
          Digital FNOL
        </div> -->
      </div>
  
      <div _ngcontent-c1="" class="login">
        <button _ngcontent-c1="" class="login-btn btn btn-primary btn-margin" (click)="login()">Login</button>
        <a _ngcontent-c1="" class="tna" target="_blank" href="https://us.crawfordandcompany.com/terms-and-conditions.aspx">Terms &amp; Conditions</a>
        <a _ngcontent-c1="" class="ast">
          <span _ngcontent-c1="">Need Assistance?</span> Contact the Crawford Help Desk
          at 1-866-641-8167
        </a>
      </div>
    </div>
  </div>