import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpBackend } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ClientConfigComponent } from './clientsetup/tabcontent/client-config/client-config.component';
import { TriggerEventComponent } from './clientsetup/tabcontent/trigger-event/trigger-event.component';
import { JobSetupComponent } from './clientsetup/tabcontent/job-setup/job-setup.component';
import { HeaderComponent } from './layouts/header/header.component';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { IconsModule } from '@progress/kendo-angular-icons';
import { PopupModule } from '@progress/kendo-angular-popup';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { MasterdataComponent } from './masterdata/masterdata.component';
import { OndemandprocessingComponent } from './ondemandprocessing/ondemandprocessing.component';
import { ContentheaderComponent } from './contentheader/contentheader.component';
import { PopupComponent } from './popup/popup.component';
import { LabelModule } from '@progress/kendo-angular-label';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { EmailconfigurationComponent } from './emailconfiguration/emailconfiguration/emailconfiguration.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule, PDFModule, ExcelModule, GridDataResult, RowArgs } from '@progress/kendo-angular-grid';
import { DialogModule, DialogService, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { EditorModule } from '@progress/kendo-angular-editor';
import { ClientsetupComponent } from './clientsetup/clientsetup.component';
import { TabstripComponent } from './clientsetup/tabstrip/tabstrip.component';
import { ClientService } from './services/client.service';
import { HistoryComponent } from './history/history.component';
import { ConfigInitializer } from './config.initializer';
import { ConfigService } from './services/config.service';
import { AllorganizationsComponent } from './masterdata/allorganizations/allorganizations.component';
import { AllsourcesystemComponent } from './masterdata/allsourcesystem/allsourcesystem.component';
import { AlljobfrequencyComponent } from './masterdata/alljobfrequency/alljobfrequency.component';
import { AlltrackedtablesComponent } from './masterdata/alltrackedtables/alltrackedtables.component';
import { AlltimecodeComponent } from './masterdata/alltimecode/alltimecode.component';
import { AllexpensecodeComponent } from './masterdata/allexpensecode/allexpensecode.component';
import { AlltriggertypeComponent } from './masterdata/alltriggertype/alltriggertype.component';
import { AllclientsComponent } from './masterdata/allclients/allclients.component';
import { AllemailconfigComponent } from './emailconfiguration/allemailconfig/allemailconfig.component';
// Import the authModule from the SDK
import { AuthClientConfig, AuthModule } from '@auth0/auth0-angular';
import { LogoutComponent } from './logout.component';
//import { AuthCallbackComponent } from './callback.component';
import{LoginComponent} from './login/login.component';
import { UserComponent } from './user/user.component'
//import { AuthHttpInterceptor } from '@auth0/auth0-angular';
//import { InterceptorService } from './services/interceptor.service';
//import { APP_BASE_HREF } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './services/loader.service';
import { InterceptorService } from './services/interceptor.service';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { AllConsumerAPIComponent } from './masterdata/all-consumer-api/all-consumer-api.component';
import { TwilioSMSClaimDetailsComponent } from './history/twilio-smsclaim-details/twilio-smsclaim-details.component';
import { ClaimWatcherHistoryComponent } from './history/claim-watcher-history/claim-watcher-history.component';


function configInitializer(
  handler: HttpBackend,
  config: AuthClientConfig
) {
  return () =>
    new HttpClient(handler)
      .get(`assets/config/config.json`)
      .toPromise()
      // Set the config that was loaded asynchronously here
      .then(response => {
        console.log("Response",response)
        ConfigService.settings = <IAppConfig>response;
        config.set({
          clientId: ConfigService.settings.AdminUIAuth0_clientID, domain: ConfigService.settings.OAuth0_Domain,
          authorizationParams: {
                redirect_uri: window.location.origin+"/callback"
              }
        });
      });
}

@NgModule({
  declarations: [
    AppComponent,
    ClientConfigComponent,
    TriggerEventComponent,
    JobSetupComponent,
    HeaderComponent,
    MasterdataComponent,
    OndemandprocessingComponent,
    ContentheaderComponent,
    PopupComponent,
    EmailconfigurationComponent,
    FooterComponent,
    ClientsetupComponent,
    TabstripComponent,
    HistoryComponent,
    AllorganizationsComponent,
    AllsourcesystemComponent,
    AlljobfrequencyComponent,
    AlltrackedtablesComponent,
    AlltimecodeComponent,
    AllexpensecodeComponent,
    AlltriggertypeComponent,
    AllclientsComponent,
    AllemailconfigComponent,
    LoginComponent,
    LogoutComponent,
    LoaderComponent,
    NotAuthorizedComponent,
    UserComponent,
    AllConsumerAPIComponent,
    TwilioSMSClaimDetailsComponent,
    ClaimWatcherHistoryComponent,
    
    //AuthCallbackComponent,
  ],


  imports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    BrowserAnimationsModule,
    InputsModule,
    NavigationModule,
    IconsModule,
    PopupModule,
    IndicatorsModule,
    ButtonsModule,
    LabelModule,
    DateInputsModule,
    DropDownsModule,
    GridModule,
    DialogModule,
    PDFModule,
    ExcelModule,
    EditorModule,
    //Import the module into the application, with configuration
    // AuthModule.forRoot({
    //   domain: configInfo.OAuth0_Domain,
    //   clientId: configInfo.AdminUIAuth0_clientID,
    //   authorizationParams: {
    //     redirect_uri: window.location.origin+"/callback"
    //   }
    // }),
    // AuthModule.forRoot({
    //   ...env.auth
    // })
    AuthModule.forRoot()
   
  ],
  providers: [
    LoaderService,
    ClientService,
    ConfigService,
    //{ provide: APP_BASE_HREF, useValue: '/ui' },
    {
      provide: APP_INITIALIZER,
      useFactory: configInitializer,
      multi: true,
      deps: [HttpBackend, AuthClientConfig]
    },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export interface IAppConfig {
  AdminUIAuth0_clientID: string
  OAuth0_Domain: string
}