import { Component, OnInit,Inject } from '@angular/core';
import { Router } from '@angular/router';
//import { UserAuthService } from '../services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent implements OnInit {

  constructor( private router: Router,public NavbarService:NavbarService, @Inject(DOCUMENT) public document: Document,private auth: AuthService) { }

  ngOnInit(): void {
    this.NavbarService.visible = false;
  }

  homepage() {
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('expires_in');
    this.auth.logout({
      logoutParams: {
        returnTo: this.document.location.origin
      }
    });
  }

}
