import { HttpClient } from '@angular/common/http';
import { Injectable,Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlGroup, OnDemandProcessingDetails } from '../models/config.model';
import { APIService } from './api.service';
import { ConfigService } from './config.service';


@Injectable({
  providedIn: 'root'
})
export class OndemandprocessingService {
  constructor(@Inject(ConfigService) private configService: ConfigService, private apiService: APIService) { }

  get urls(): UrlGroup {
    return this.configService.getApiUrls('adminconfiguration');
  }

  public GetOnDemandProcessedClaimsDetailsByClientId(clientId: number): Observable<OnDemandProcessingDetails[]> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/clientconfiguration/` + clientId + `/getOnDemandClaimProcessedDetails`;
    return this.apiService.get<OnDemandProcessingDetails[]>(url);
  }

  public postOnDemandProcessedClaimsDetails(data: any): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/clientconfiguration/createOnDemandProcessedClaims`;
    return this.apiService.post<number>(url, data);
  }

  public putOnDemandProcessedClaimsDetails(data: any): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/clientconfiguration/updateOnDemandProcessedClaims`;
    return this.apiService.put<number>(url, data);
  }

  public StopOnDemandProcessedClaimsDetails(data: any): Observable<any> {
    data.OndemandClaimProcessingId = data.id;
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/clientconfiguration/stopOnDemandProcessing`;
    return this.apiService.put<number>(url, data);
  }

  }
