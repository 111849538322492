import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent {
  constructor(private auth: AuthService) {}
  //constructor() {}

  login() {
    this.auth.loginWithRedirect({
      appState: { target: '/ClientSetup' }
    })
  }
}
