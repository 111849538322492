import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, } from '@angular/core';
//import { AuthService } from '@auth0/auth0-angular';
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from 'rxjs/operators';
import { LoaderService } from './loader.service';
//import { UserAuthService } from './auth.service';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';

import { UserAuthService } from '../auth/services/user-auth.service';
@Injectable({
  providedIn: 'root'
})
export class InterceptorService {

  //constructor(private auth: AuthService) { }
  constructor(public loaderService: LoaderService, public UserAuthService: UserAuthService, public auth: AuthService, @Inject(DOCUMENT) public document: Document,) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler,): Observable<HttpEvent<any>> {

    this.loaderService.show();
    const jwtInjectionConfig = this.UserAuthService.getJwtInjectionConfig();
    // const expiresOn = this.UserAuthService.getJwtInjectionConfig();
    //let currentTime = "2023-08-24T11:07:50.0758725Z";
    const now = new Date();
    let secondsToAdd = parseInt(jwtInjectionConfig.expires_in);
    let expires_in = new Date().toISOString();
    if(secondsToAdd){
       expires_in = new Date(now.getTime() + secondsToAdd * 1000).toISOString();
    }

    //console.log(expires_in);
    let currentTime = new Date().toISOString();

    if (jwtInjectionConfig.token && jwtInjectionConfig.token != "undefined") {
      if (currentTime > expires_in) {
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('role');
        sessionStorage.removeItem('expires_in');
        this.auth.logout({
          logoutParams: {
            returnTo: this.document.location.origin
          }
        });
      }
    }
    

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${jwtInjectionConfig.token}`,
      }
    });

    return next.handle(request).pipe(
      finalize(() => this.loaderService.hide()),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = "";
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        const errormes = error.error;
        if (error.status == 400 && errormes.message) {
          errorMessage = errormes.message;
          // this.toaster.showToast({
          //   message: errorMessage,
          //   type: 'danger',
          //   duration: 900000
          // });
        }

        else {
          if (errorMessage.indexOf("Error Code:") >= 0) {
            // this.toaster.showToast({
            //   message: "Something went wrong, please try again later or contact support",
            //   type: "danger"
            // });
          }

        }
        return throwError(errorMessage);
      }));
  }
}
