<div class="card-box">
  <form class="k-form" [formGroup]="emailForm">
    <div class="row">
      <div class="col-md-3 col-sm-12 mb-4">
        <kendo-formfield>
          <kendo-floatinglabel
            text="Select Client Name"
            [labelCssStyle]="{ 'font-size': '15px' }"
          >
            <kendo-dropdownlist
              [filterable]="true"
              textField="name"
              valueField="id"
              (filterChange)="clientFilter($event)"
              [formControlName]="'clientName'"
              #clientName
              required
              [data]="clientData"
              *ngIf="!isEditMode"
            ></kendo-dropdownlist>
            <kendo-textbox
              *ngIf="isEditMode"
              [readonly]="true"
              [formControlName]="'clientName'"
            >
            </kendo-textbox>
          </kendo-floatinglabel>
          <kendo-formerror>Client Name is required</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col-md-3 col-sm-12 mb-4">
        <kendo-formfield>
          <kendo-floatinglabel
            text="Select Trigger Type"
            [labelCssStyle]="{ 'font-size': '15px' }"
          >
            <kendo-dropdownlist
              placeholder=""
              [filterable]="true"
              textField="type"
              valueField="id"
              [formControlName]="'triggerType'"
              #triggerType
              required
              (filterChange)="triggerTypeFilter($event)"
              [data]="triggertypeSource"
              *ngIf="!isEditMode"
            >
            </kendo-dropdownlist>
            <kendo-textbox
              *ngIf="isEditMode"
              [readonly]="true"
              [formControlName]="'triggerType'"
            >
            </kendo-textbox>
          </kendo-floatinglabel>
          <kendo-formerror>Trigger Type is required</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col-md-3 col-sm-12 mb-4">
        <kendo-formfield>
          <kendo-floatinglabel
            text="To Email"
            [labelCssStyle]="{ 'font-size': '15px' }"
          >
            <kendo-textbox formControlName="toEmails" #toEmails required>
            </kendo-textbox>
          </kendo-floatinglabel>
          <kendo-formerror *ngIf="f['toEmails']?.errors?.['required']"
            >Email required</kendo-formerror
          >
          <kendo-formerror *ngIf="f['toEmails']?.errors?.['invalidEmail']"
            >Please enter valid email id</kendo-formerror
          >
        </kendo-formfield>
      </div>
      <div class="col-md-3 col-sm-12 mb-4">
        <kendo-formfield>
          <kendo-floatinglabel
            text="Cc Email"
            [labelCssStyle]="{ 'font-size': '15px' }"
          >
            <kendo-textbox [formControlName]="'ccEmail'"> </kendo-textbox>
          </kendo-floatinglabel>
          <kendo-formerror *ngIf="f['ccEmail']?.errors?.['invalidEmail']"
            >Please enter valid email id</kendo-formerror
          >
        </kendo-formfield>
      </div>
      <div class="col-md-3 col-sm-12 mb-4">
        <kendo-formfield>
          <kendo-floatinglabel
            text="Bcc Email"
            [labelCssStyle]="{ 'font-size': '15px' }"
          >
            <kendo-textbox [formControlName]="'bccEmail'"> </kendo-textbox>
          </kendo-floatinglabel>
          <kendo-formerror *ngIf="f['bccEmail']?.errors?.['invalidEmail']"
            >Please enter valid email id</kendo-formerror
          >
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-sm-12 mb-4">
        <kendo-formfield>
          <kendo-floatinglabel
            text="Subject"
            [labelCssStyle]="{ 'font-size': '15px' }"
          >
            <kendo-textbox [formControlName]="'subject'" #subject required>
            </kendo-textbox>
          </kendo-floatinglabel>
          <kendo-formerror>Subject required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-sm-12 mb-4">
        <kendo-formfield>
          <kendo-label text="Email Body"></kendo-label>
          <br />
          &nbsp;
          <kendo-editor
            [formControlName]="'body'"
            #body
            required
            style="height: 250px; width: 1000px"
          ></kendo-editor>
          <kendo-formerror>Body required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-sm-12 mb-4" style="margin-top: 35px !important">
        <kendo-label
          class="k-checkbox-label"
          text="Is JSON File needs to be attached"
        >
          &nbsp;
          <input
            type="checkbox"
            [formControlName]="'isJsonNeedsToBeAttached'"
            #isJsonNeedsToBeAttached
            kendoCheckBox
          />
        </kendo-label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-sm-12 mb-4" style="margin-top: 35px !important">
        <kendo-label
          class="k-checkbox-label"
          text="Is Active"
        >
          &nbsp;
          <input
            type="checkbox"
            [formControlName]="'isActive'"
            #isActive
            kendoCheckBox
          />
        </kendo-label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3 col-sm-12 mb-4">
        <kendo-button
          _ngcontent-whw-c5
          kendoButton
          [routerLink]="['/EmailConfiguration']"
          size="medium"
          rounded="medium"
          style="color: #fff; background-color: #193971 !important"
          (click)="exit()"
        >
          Back
        </kendo-button>

        <kendo-button
          _ngcontent-whw-c5
          style="color: #fff; background-color: #193971 !important"
          [disabled]="disabled()"
          (click)="saveEmail()"
          >Save</kendo-button
        >
      </div>
    </div>
  </form>
  <div kendoDialogContainer></div>
</div>
