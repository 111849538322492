<div class="card-box">
  <form class="k-form" [formGroup]="sourcesystemForm">
    <div [style.height.px]="50">
      <kendo-button
        _ngcontent-whw-c5
        style="color: #fff; background-color: #193971 !important"
        (click)="open()"
        >Add Source System</kendo-button
      >
    </div>
    <div class="row">
      <kendo-grid
        [reorderable]="true"
        [kendoGridBinding]="sourceSystem"
        filterable="menu"
        [resizable]="true"
        cssClass="grid-height"
        [pageSize]="10"
        [sortable]="true"
        [groupable]="true"
        [pageable]="{
          pageSizes: pageSizes
        }"
        style="color: #fff"
      >
        <ng-template kendoGridToolbarTemplate>
          <kendo-grid-spacer></kendo-grid-spacer>
          <button
            kendoGridExcelCommand
            type="button"
            icon="file-excel"
            title="Download Excel"
          ></button>
          <button
            kendoGridPDFCommand
            type="button"
            icon="file-pdf"
            title="Download Pdf"
          ></button>
        </ng-template>

        <kendo-grid-column
          field="id"
          title="Id"
          [width]="60"
          filter="numeric"
          [headerClass]="'headerCustomClass'"
        ></kendo-grid-column>
        <kendo-grid-column
          field="name"
          title="Source System"
          [width]="120"
          [headerClass]="'headerCustomClass'"
        ></kendo-grid-column>
        <kendo-grid-column
          field="isActive"
        filter="boolean"
        title="Is Active"
          [width]="100"
          [headerClass]="'headerCustomClass'"
        ></kendo-grid-column>
        <kendo-grid-column
          field="Action"
          [width]="100"
          [filterable]="false"

          [headerClass]="'headerCustomClass'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <button
              kendoButton
              (click)="
                openDialog(dataItem.name, dataItem.id, dataItem.isActive)
              "
              style="color: #fff; background-color: #193971 !important"
            >
              Edit
            </button>
          </ng-template>
        </kendo-grid-column>

        <!-- <kendo-dialog #editDialog
        [title]="Title"
        >
         <kendo-textBox [(ngModel)]="Name"></kendo-textBox>
          <kendo-dialog-actions>
            <button kendoButton (click)="onCancel()">Cancel</button>
            <button kendoButton [disabled]="!formValid" (click)="onSave()">Save</button>
          </kendo-dialog-actions
        </kendo-dialog> -->

        <kendo-grid-pdf
          [fileName]="'Source system.pdf'"
          [allPages]="true"
          paperSize="A4"
          [repeatHeaders]="true"
          [landscape]="true"
          style="float: right"
        >
          <kendo-grid-pdf-margin
            top="2cm"
            left="1cm"
            right="1cm"
            bottom="2cm"
          ></kendo-grid-pdf-margin>
          <ng-template
            kendoGridPDFTemplate
            let-pageNum="pageNum"
            let-totalPages="totalPages"
          >
            <div class="page-template">
              <div class="header">
                <div style="float: right">
                  Page {{ pageNum }} of {{ totalPages }}
                </div>
              </div>
              <div class="footer">Page {{ pageNum }} of {{ totalPages }}</div>
            </div>
          </ng-template>
        </kendo-grid-pdf>
        <kendo-grid-excel [fileName]="'Source system.xlsx'" [fetchData]="allData"></kendo-grid-excel>
      </kendo-grid>

      <ng-container *ngIf="isaddTextboxVisible">
        <kendo-formfield>
          <kendo-dialog
            [title]="Title"
            *ngIf="opened"
            (close)="close()"
            [minWidth]="250"
            [width]="450"
          >
        <kendo-formfield>
            <kendo-floatinglabel text="Source System Name">
              <kendo-textbox
                input="text"
                required
                (input)="onInput(Textbox.value)"
                [maxlength]="100"


                *ngIf="isaddTextboxVisible"
                #Textbox
                [formControlName]="'name'"
              >
              </kendo-textbox>
            </kendo-floatinglabel>
            <kendo-formerror *ngIf="getFormControl['name']?.errors?.['pattern']">Only Alphabets and numerics are allowed</kendo-formerror>
            <kendo-formerror *ngIf="getFormControl['name']?.errors?.['required']">name required</kendo-formerror>


          </kendo-formfield>

            <br />
            <kendo-button
              _ngcontent-whw-c5
              style="color: #fff; background-color: #193971 !important"
              (click)="saveData()"
              [disabled]="!sourcesystemForm.valid"
              >Save</kendo-button
            >
          </kendo-dialog>
        </kendo-formfield>
      </ng-container>

      <kendo-formfield>
        <ng-container *ngIf="isTextboxVisible">
          <kendo-dialog
            [title]="Title"
            *ngIf="openedEdit"

            (close)="close()"
            [minWidth]="250"
            [width]="450"

          >
          <kendo-formfield>
            <kendo-floatinglabel text="Source System Name">

              <kendo-textbox
                input="text"
                *ngIf="isTextboxVisible"
                #Textbox
                (input)="onInput(Textbox.value)"
                [formControlName]="'name'"
                showErrorIcon="initial"
              ></kendo-textbox>
              <kendo-formerror *ngIf="getFormControl['name']?.errors?.['pattern']">Only Alphabets and numerics are allowed</kendo-formerror>
            <kendo-formerror *ngIf="getFormControl['name']?.errors?.['required']">name required</kendo-formerror>

            </kendo-floatinglabel>

          </kendo-formfield>
          <kendo-label class="k-checkbox-label" [for]="isActive"
                ><input
                  [formControlName]="'isActive'"
                  type="checkbox"

                  #isActive
                  kendoCheckBox
                />
                Is Active
              </kendo-label>
            <br />
            <kendo-button
              _ngcontent-whw-c5
              style="color: #fff; background-color: #193971 !important"
              (click)="saveData()" [disabled]="!sourcesystemForm.dirty"
              >Save</kendo-button
            >
          </kendo-dialog>
        </ng-container>
      </kendo-formfield>
    </div>
  </form>
  <div kendoDialogContainer></div>
</div>
