import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contentheader',
  templateUrl: './contentheader.component.html',
  styleUrls: ['./contentheader.component.scss']
})
export class ContentheaderComponent implements OnInit {
  @Input()
  headerText: string | undefined;

  constructor() { }

  ngOnInit(): void {  
  }
}
