import { HttpClient } from '@angular/common/http';
import { Injectable,Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { EmailConfig } from '../models/config.model';
import { UrlGroup } from '../models/config.model';
import { APIService } from './api.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class EmailConfigurationService {

  constructor(@Inject(ConfigService) private configService: ConfigService, private apiService: APIService) { }

  get urls(): UrlGroup {
    return this.configService.getApiUrls('adminconfiguration');
  }
  public getClientEmailTemplateDetails(clientId: number): Observable<EmailConfig[]> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/clientemailtemplate/` + clientId + `/getClientEmailTemplateDetails`;
    return this.apiService.get<EmailConfig[]>(url);
  }

  public getEmailTemplate(clientId: number,id:number): Observable<EmailConfig[]> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/clientemailtemplate/` + clientId + `/getClientEmailTemplateDetails?id=`+id;
    return this.apiService.get<EmailConfig[]>(url);
  }

  public postClientEmailTemplate(data: any): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/clientemailtemplate/CreateClientEmailTemplate`;
    return this.apiService.post<number>(url, data);
  }

  public putClientEmailTemplate(data: EmailConfig): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/clientemailtemplate/updateClientEmailTemplate`;
    return this.apiService.put<number>(url, data);
  }

  }


