import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'admin-callback',
  template: '',
})
export class AuthCallbackComponent {
  constructor(public router: Router) {
  }
}
