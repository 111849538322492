<div class="card-box">
  <form class="k-form" [formGroup]="trackedtableForm">
    <div class="row">
      <kendo-grid
        [reorderable]="true"
        [kendoGridBinding]="trackedTables"
        filterable="menu"
        [resizable]="true"
        cssClass="grid-height"
        [pageSize]="10"
        [sortable]="true"
        [groupable]="true"
        [reorderable]="true"
        [pageable]="{
          pageSizes:pageSizes
        }"
        style="color: #fff"
      >
        <ng-template kendoGridToolbarTemplate>
          <kendo-grid-spacer></kendo-grid-spacer>
          <button
            kendoGridExcelCommand
            type="button"
            icon="file-excel"
            title="Download Excel"
          ></button>
          <button
            kendoGridPDFCommand
            type="button"
            icon="file-pdf"
            title="Download Pdf"
          ></button>
        </ng-template>

        <kendo-grid-column
          field="id"
          title="Id"
          [width]="60"
          filter="numeric"
          [headerClass]="'headerCustomClass'"
        ></kendo-grid-column>
        <kendo-grid-column
          field="tableName"
          title="Table Name"
          [width]="120"
          [headerClass]="'headerCustomClass'"
        ></kendo-grid-column>
        <kendo-grid-column
          field="isActive"
        filter="boolean"
        title="Is Active"
          [width]="100"
          [headerClass]="'headerCustomClass'"
        ></kendo-grid-column>
        <kendo-grid-column
          field="isRequiredToClaimFetch"
          title="Is Required To Claim Fetch"
          [width]="100"
          [headerClass]="'headerCustomClass'"
        ></kendo-grid-column>
        <kendo-grid-column
          field="trackedColumnName"
          title="Tracked Column Name"
          [width]="100"
          [headerClass]="'headerCustomClass'"
        ></kendo-grid-column>

        <kendo-grid-pdf
          [fileName]="'Tracked Tables.pdf'"
          [allPages]="true"
          paperSize="A4"
          [repeatHeaders]="true"
          [landscape]="true"
          style="float: right"
        >
          <kendo-grid-pdf-margin
            top="2cm"
            left="1cm"
            right="1cm"
            bottom="2cm"
          ></kendo-grid-pdf-margin>
          <ng-template
            kendoGridPDFTemplate
            let-pageNum="pageNum"
            let-totalPages="totalPages"
          >
            <div class="page-template">
              <div class="header">
                <div style="float: right">
                  Page {{ pageNum }} of {{ totalPages }}
                </div>
              </div>
              <div class="footer">Page {{ pageNum }} of {{ totalPages }}</div>
            </div>
          </ng-template>
        </kendo-grid-pdf>
        <kendo-grid-excel [fileName]="'Tracked Tables.xlsx'" [fetchData]="allData"></kendo-grid-excel>
      </kendo-grid>

      <br />
      <br />
    </div>
  </form>
</div>
