<app-contentheader headerText="Master Data"></app-contentheader>
<div class="row" class="nav-item">
  <kendo-tabstrip >
    <kendo-tabstrip-tab title="Source System" [selected]="true">
      <ng-template kendoTabContent>
        <app-allsourcesystem [id]="id"></app-allsourcesystem>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Organization">
      <ng-template kendoTabContent>
        <app-allorganizations [id]="id"></app-allorganizations>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Tracked Tables">
      <ng-template kendoTabContent>
        <app-alltrackedtables [id]="id"></app-alltrackedtables>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Job Frequency">
      <ng-template kendoTabContent>
        <app-alljobfrequency [id]="id"></app-alljobfrequency>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Trigger Type">
      <ng-template kendoTabContent>
        <app-alltriggertype [id]="id"></app-alltriggertype>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Expense Code">
      <ng-template kendoTabContent>
        <app-allexpensecode [id]="id"></app-allexpensecode>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Time Code">
      <ng-template kendoTabContent>
        <app-alltimecode [id]="id"></app-alltimecode>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Client">
      <ng-template kendoTabContent>
        <app-allclients [id]="id"></app-allclients>
      </ng-template>
    </kendo-tabstrip-tab>
    <!-- <kendo-tabstrip-tab title="API Consumer" *ngIf="userRole == 'superadmin'">
      <ng-template kendoTabContent>
        <app-all-consumer-api ></app-all-consumer-api>
      </ng-template>
    </kendo-tabstrip-tab> -->
  </kendo-tabstrip>
</div>
