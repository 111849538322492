<app-contentheader headerText="Users"></app-contentheader>
<!-- Showing users data on the grid -->
<div class="card-box">
  <div *ngIf="!adduser">
    <div [style.height.px]="50">
      <button *ngIf="userRole == 'superadmin'"
        _ngcontent-whw-c5
        kendoButton
        size="medium"
        rounded="medium"
        style="color: #fff; background-color: #193971 !important"
        (click)="addNewUser()"
      >
        Add New User
      </button>
    </div>
    <kendo-grid
      [kendoGridBinding]="userGridView"
      [reorderable]="true"
      filterable="menu"
      cssClass="grid-height"
      [pageSize]="10"
      [pageable]="{pageSizes:pageSizes}"
      [sortable]="true"
      [groupable]="true"
      [reorderable]="true"
      [resizable]="true"
    >
      <ng-template kendoGridToolbarTemplate>
        <input
        [style.width.px]="500"
        placeholder="Search in all columns..."
        kendoTextBox
        (input)="onFilter($event)"
      />
        <kendo-grid-spacer></kendo-grid-spacer>
        <button
          kendoGridExcelCommand
          type="button"
          icon="file-excel"
          title="Download Excel"
        ></button>
        <button
          kendoGridPDFCommand
          icon="file-pdf"
          title="Download Pdf"
        ></button>
        <kendo-grid-column-chooser></kendo-grid-column-chooser>
      </ng-template>
      <kendo-grid-column field="userId" title="User Id" [width]="250">
      </kendo-grid-column>
      <kendo-grid-column field="company" title="Company" [width]="120">
      </kendo-grid-column>
      <!-- <kendo-grid-column
        field="address"
        title="Address"
        [width]="170"
      ></kendo-grid-column> -->
      <kendo-grid-column
        field="role"
        title="Role"
        [width]="190"
      ></kendo-grid-column>
      <kendo-grid-column
        field="isActive"
        title="Is Active"
        filter="boolean"

        [width]="120"
      ></kendo-grid-column>
      <kendo-grid-column field="createdBy" title="Created By" [width]="150">
      </kendo-grid-column>
      <kendo-grid-column
        field="lastLoginDate"
        title="Last Login Date"
        [width]="220"
        [hidden]="true"
      ></kendo-grid-column>
      <kendo-grid-column
        field="createdDate"
        title="Created Date"
        [width]="220"
        [hidden]="true"
      ></kendo-grid-column>
      <kendo-grid-column
        field="modifiedDate"
        title="Modified Date"
        [width]="220"
        [hidden]="true"
      ></kendo-grid-column>
      <kendo-grid-column
        field="modifiedBy"
        title="Modified By"
        [width]="220"
        [hidden]="true"
      ></kendo-grid-column>

      <kendo-grid-column field="Action" [filterable]="false" [width]="90" *ngIf="userRole == 'superadmin'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button
            kendoButton
            style="color: #fff; background-color: #193971 !important"
            (click)="editUsers(dataItem)"
          >
            Edit
          </button>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-pdf
        fileName="Users Details.pdf"
        [allPages]="true"
        [repeatHeaders]="true"
      ></kendo-grid-pdf>
      <kendo-grid-excel fileName="Users Details.xlsx" [fetchData]="allData"></kendo-grid-excel>
    </kendo-grid>
  </div>
  <br />
  <div *ngIf="adduser">
    <form class="k-form" [formGroup]="userform">
      <div class="row">
        <div class="col-md-3 col-sm-12 mb-4">
          <kendo-formfield>
            <kendo-floatinglabel
              text="User Id"
              [labelCssStyle]="{ 'font-size': '15px' }"
            >
              <kendo-textbox
                [formControlName]="'userId'"
                required
              ></kendo-textbox>
            </kendo-floatinglabel>
            <kendo-formerror *ngIf="f['userId']?.errors?.['required']"
              >Email required</kendo-formerror
            >
            <kendo-formerror *ngIf="f['userId']?.errors?.['invalidEmail']"
              >Please enter valid email id</kendo-formerror
            >
          </kendo-formfield>
        </div>

        <div class="col-md-3 col-sm-12 mb-4">
          <kendo-formfield>
            <kendo-floatinglabel
              text="Address"
              [labelCssStyle]="{ 'font-size': '15px' }"
            >
              <kendo-textbox
                [formControlName]="'address'"

              ></kendo-textbox>
            </kendo-floatinglabel>
            <!-- <kendo-formerror *ngIf="f['address']?.errors?.['required']"
              >Address required</kendo-formerror
            > -->
          </kendo-formfield>
        </div>
        <div class="col-md-3 col-sm-12 mb-4">
          <kendo-formfield>
            <kendo-floatinglabel
              text="Comapny"
              [labelCssStyle]="{ 'font-size': '15px' }"
            >
              <kendo-textbox
                [formControlName]="'company'"
                required
              ></kendo-textbox>
            </kendo-floatinglabel>
            <kendo-formerror *ngIf="f['company']?.errors?.['required']"
              >Comapany Name is required</kendo-formerror
            >
          </kendo-formfield>
        </div>
        <div class="col-md-3 col-sm-12 mb-4">
          <kendo-formfield>
            <kendo-floatinglabel
              text="Contact Number"
              [labelCssStyle]="{ 'font-size': '15px' }"
            >
              <kendo-textbox
                [formControlName]="'contactNo'"

              ></kendo-textbox>
            </kendo-floatinglabel>
            <kendo-formerror *ngIf="f['contactNo']?.errors?.['pattern']"
              >Contact Number is required</kendo-formerror
            >
            <kendo-formerror *ngIf="f['contactNo']?.errors?.['maxlength']"
              >Contact Number should be of 10 digits only</kendo-formerror
            >
          </kendo-formfield>
        </div>
        <div class="col-md-3 col-sm-12 mb-4">
          <kendo-formfield>
          <kendo-floatinglabel
            text="Role"
          >
            <kendo-dropdownlist
              [filterable]="false"
              textField="value"
              valueField="id"
              [formControlName]="'AdminUserRole'"
              [data]="userRoleData"
              required
            ></kendo-dropdownlist>
          </kendo-floatinglabel>
          <kendo-formerror *ngIf="f['AdminUserRole']?.errors?.['required']"
              >Role is Required</kendo-formerror
            >
        </kendo-formfield>
        </div>

        <div class="col-md-3 col-sm-12 mb-4">
          <kendo-label
            *ngIf="isEditMode"
            class="k-checkbox-label"
            [for]="isActive"
            ><input
              [formControlName]="'isActive'"
              type="checkbox"
              #isActive
              kendoCheckBox
            />
            Is Active
          </kendo-label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-sm-12 mb-4">
          <kendo-button
            _ngcontent-whw-c5
            kendoButton
            size="medium"
            rounded="medium"
            style="color: #fff; background-color: #193971 !important"
            (click)="exit()"
            *ngIf="adduser"
          >
            Back
          </kendo-button>

          <kendo-button
            _ngcontent-whw-c5
            style="color: #fff; background-color: #193971 !important"
            (click)="saveuser()"
            [disabled]="disabled()"
            >Save</kendo-button
          >
        </div>
      </div>
    </form>
    <div kendoDialogContainer></div>
  </div>
</div>
