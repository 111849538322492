import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientConfigComponent } from './clientsetup/tabcontent/client-config/client-config.component';
import { JobSetupComponent } from './clientsetup/tabcontent/job-setup/job-setup.component';
import { TriggerEventComponent } from './clientsetup/tabcontent/trigger-event/trigger-event.component';
import { EmailconfigurationComponent } from './emailconfiguration/emailconfiguration/emailconfiguration.component';
import { MasterdataComponent } from './masterdata/masterdata.component';
import { OndemandprocessingComponent } from './ondemandprocessing/ondemandprocessing.component';
import { ClientsetupComponent } from './clientsetup/clientsetup.component';
import { HistoryComponent } from './history/history.component';
import { AllemailconfigComponent } from './emailconfiguration/allemailconfig/allemailconfig.component';
 import { AuthCallbackComponent } from './callback.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { CallbackGaurdService } from './services/callback.gaurd.service';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { UserComponent } from './user/user.component';
import { TwilioSMSClaimDetailsComponent } from './history/twilio-smsclaim-details/twilio-smsclaim-details.component'
const routes: Routes = [
  {
    path: 'callback',
    canActivate: [ CallbackGaurdService ],
    component: AuthCallbackComponent
  },

  // {path:'', component: LoginComponent},
  // {path:'logout', component: LogoutComponent},
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {path:'ClientSetup', component: ClientsetupComponent,canActivate: [CallbackGaurdService],},
  {path:'MasterData', component: MasterdataComponent,canActivate: [AuthGuard],},
  {path:'OnDemandProcessing', component: OndemandprocessingComponent,canActivate: [AuthGuard],},
  {path:'ClientConfiguration', component: ClientConfigComponent,canActivate: [AuthGuard],},
  {path:'EmailConfiguration', component: AllemailconfigComponent,canActivate: [AuthGuard],},
  {path:'JobSetup', component: JobSetupComponent,canActivate: [AuthGuard],},
  {path:'TriggerEvent', component: TriggerEventComponent,canActivate: [AuthGuard],},
  {path:'History', component: HistoryComponent,canActivate: [AuthGuard],},
  {path:'Users', component:UserComponent,canActivate: [AuthGuard]},
  {path:'twilio-smsclaim-details', component:TwilioSMSClaimDetailsComponent,canActivate: [AuthGuard]},
  {path:'not-authorized', component:NotAuthorizedComponent},
  // {path: 'callback',component: AuthCallbackComponent},


];

// const routes: Routes = [
//    //{path:'',pathMatch: 'full',redirectTo: 'ui'},
//   {path:'', component: ClientsetupComponent},
//   // {path:'logout', component: LogoutComponent},
//   {path:'ClientSetup', component: ClientsetupComponent},
//   {path:'MasterData', component: MasterdataComponent},
//   {path:'OnDemandProcessing', component: OndemandprocessingComponent},
//   {path:'ClientConfiguration', component: ClientConfigComponent},
//   {path:'EmailConfiguration', component: AllemailconfigComponent},
//   {path:'JobSetup', component: JobSetupComponent},
//   {path:'TriggerEvent', component: TriggerEventComponent},
//   {path:'History', component: HistoryComponent},
//   // {path: 'callback',component: AuthCallbackComponent},

// ];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
