import { HttpClient } from '@angular/common/http';
import { Injectable,Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlGroup, TriggerEventConfigDetails } from '../models/config.model';
import { APIService } from './api.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class TriggerEventService {
  constructor(@Inject(ConfigService) private configService: ConfigService, private apiService: APIService) { }

  get urls(): UrlGroup {
    return this.configService.getApiUrls('adminconfiguration');
  }

  public getClientTriggerEventConfigurationDetails(clientConfigurationId : number): Observable<TriggerEventConfigDetails> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/clientconfiguration/` + clientConfigurationId  + `/getClientTriggerEventConfigurationDetails`;
    return this.apiService.get<TriggerEventConfigDetails>(url);
  }

  public createClientTriggerEventConfiguration(data: any): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/clientconfiguration/CreateClientTriggerEventConfiguration`;
    return this.apiService.post<number>(url, data);
  }

  public updateClientTriggerEventConfiguration(data: TriggerEventConfigDetails): Observable<any> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLCommand) + `adminconfiguration-command/v1/clientconfiguration/updateClientTriggerEventConfiguration`;
    return this.apiService.put<number>(url, data);
  }
}
