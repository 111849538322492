import { Injectable,Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { History } from '../models/config.model';
import { UrlGroup } from '../models/config.model';
import { APIService } from './api.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(@Inject(ConfigService) private configService: ConfigService, private apiService: APIService) { }

  get urls(): UrlGroup {
    return this.configService.getApiUrls('adminconfiguration');
  }

  public getHistory(clientId: number,skip:number, pageSize:number): Observable<History[]> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/clientconfiguration/`+ clientId+`/` + skip +`/`+pageSize+`/getClaimWatcherHistoryDetails`;
    return this.apiService.get<History[]>(url);
  }

  public getJsonHistoryByClientId(clientId: number ): Observable<History[]> {
    const url = this.apiService.sanitizeUrl(this.urls.PrefixURLRead) + `adminconfiguration-read/v1/clientconfiguration/`+ clientId +`/getClaimWatcherHistoryJsonById`;
    return this.apiService.get<any[]>(url);
  }

  // public getClaimWatcherHistoryDetails(): Observable<History[]> {
  //   const url = this.apiService.sanitizeUrl(this.urls.prefixurl) + `adminconfiguration-read/v1/clientconfiguration/allclientconfiguration`;
  //   return this.apiService.get<History[]>(url);
  // }

}
