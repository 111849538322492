import configInfo from '../assets/config/config.json';


export const environment = {
  production: true,
  auth: {
    domain: configInfo.OAuth0_Domain,
    clientId: configInfo.AdminUIAuth0_clientID,
    authorizationParams: {
      redirect_uri: window.location.origin + "/callback"
    }
  }
};
